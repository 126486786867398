<template>
    <div class="top-nav-fixed">
        <div id="top-nav-container" class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center">
            <div class="header-space">
                <div class="row">
                    <div class="w-50 w-sm-50 text-center d-flex align-items-center">
                        <div class="nav-icon-wr d-none d-md-flex ml-0">
                            <a @click="_goToRoute('/home')">
                                <img src="../../public/images/logo.png" width="70" height="63" 
                                    style="object-fit:cover">
                            </a>
                        </div>
                        <div class="nav-icon-wr pl-2 ml-2" v-if="userInfo && userInfo.home_channel && userInfo.home_channel.length > 0">
                                <img @click="_goToRoute('/home')" src="../assets/images/cockfight icon.png" class="dancing-image" 
                                width="47" height="47" 
                                style="object-fit:cover" :class="$route.path=='/home' ? 'dancing-image-active' : ''">
                        </div>
                        
                        <div class="nav-icon-wr pl-2 ml-2" style="margin-top:-2px; position: relative;" v-if="userInfo && userInfo.live_channel && userInfo.live_channel.length > 0">
                                <img @click="_goToRoute('/live')" src="../assets/images/live-casino-icon.png" class="dancing-image"  
                                width="45" height="45" 
                                style="object-fit:cover" :class="$route.path=='/live' ? 'dancing-image-active' : ''">
                                <img class="lotto-home" src="../assets/images/new-icon.gif" width="35" height="35" 
                                style="object-fit:cover; position: absolute; top: 23px;
                                right: -11px;" >
                        </div>
                        <div class="nav-icon-wr pl-2 ml-2" style="margin-top:-2px; position: relative" v-if="userInfo && userInfo.slot_channel && userInfo.slot_channel.length > 0">
                                <img @click="_goToRoute('/LOTTO')" src="../assets/images/h_slot_machine.png" class="dancing-image"  
                                width="45" height="45" 
                                style="object-fit:cover" :class="$route.path=='/LOTTO' || $route.path=='/YUKI' || $route.path=='/LOTTERY' ? 'dancing-image-active' : ''">
                                <img class="lotto-home" src="../assets/images/new-icon.gif" width="35" height="35" 
                                style="object-fit:cover; position: absolute; top: 23px;
                                right: -11px;" >
                        </div>
                    </div>
                    <div class="nav-credits-wr w-50 w-sm-50 gold-text mt-1" >
                        <a href="#" class="d-flex align-items-center justify-content-end gp-credits">
                            <div class="nav-credits-wr w-50 w-sm-50 gold-text pr-0">
                                <a href="#" class="d-flex align-items-center justify-content-end gp-credits">
                                    <div class="credits-data d-flex display_balance">
                                        <img src="../assets/images/cash-logo.png" width="25" height="18" class="pr-1 " style="margin-top:2.5px">
                                        <span style="color:gold; font-weight:700">{{userInfo && userInfo.balance ? _formatCurrency(userInfo ):''}}</span> 
                <span class="format-currency" style="font-family:'khmer mef1'"> {{ userInfo && userInfo.balance ? _displayCurrencySymbol( userInfo.account_currency ) : '' }}</span>
                                    </div>
                                    
                                    <v-btn @click="playBgAudio" icon>
                                        <v-icon>{{ isAudioPlay ? 'mdi-music' : 'mdi-music-off' }}</v-icon>
                                    </v-btn>
                                    <v-btn @click="changeSound" icon>
                                        <v-icon>{{ isSound ? 'mdi-volume-high' : 'mdi-volume-mute' }}</v-icon>
                                    </v-btn>
                                    <div class="bm-icon" @click="_onSubmitDrawer(!passDrawer)"><svg data-prefix="fa"
                                            data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                            data-fa-i2svg="" class="svg-inline--fa fa-bars fa-w-14 fa-lg">
                                            <path fill="currentColor"
                                                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                                            </path>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                            
                        </a>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import "@/assets/css/header.css";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
    props: ['passUserInfo', 'passDrawer'],
    data: () => ({
        websiteLogo: '//static3.goperya.com/img/logo-3.png?v=10.60',
        audioLogItems: [],
        chosenName: '',
        audios: [
            {
                id: 'Winter_Song',
                name: 'Winter_Song',
                file: new Audio(`/sound/Winter_Song.mp3`),
                isPlaying: false
            },
            {
                id: 'Orange_Days',
                name: 'Orange_Days',
                file: new Audio(`/sound/Orange_Days.mp3`),
                isPlaying: false
            },
            {
                id: 'Thinking_About_You',
                name: 'Thinking_About_You',
                file: new Audio(`/sound/Thinking_About_You.mp3`),
                isPlaying: false
            },
            {
                id: 'Thinking_About_You',
                name: 'Thinking_About_You',
                file: new Audio(`/sound/Thinking_About_You.mp3`),
                isPlaying: false
            },
            // {
            //     id: 'Winter_Song',
            //     name: 'Winter_Song',
            //     file: new Audio(`/sound/r1.mp3`),
            //     isPlaying: false
            // },
            // {
            //     id: 'Orange_Days',
            //     name: 'Orange_Days',
            //     file: new Audio(`/sound/r2.mp3`),
            //     isPlaying: false
            // },
            // {
            //     id: 'Thinking_About_You',
            //     name: 'Thinking_About_You',
            //     file: new Audio(`/sound/r3.mp3`),
            //     isPlaying: false
            // },
        ]
    }),
    computed: {
        ...mapGetters("$_modules", {
            isSound: "getIsSound",
            isAudioPlay: "getIsAudioPlay",
            userInfo:"getUserInfo"
        }),
        ...mapState("$_modules", {
            isAudioPlay: "isAudioPlay",
        }),
        
    },
    watch: {
        isAudioPlay: function (newVal) {
            if (newVal == false) {
                this.pauseAll();
            }
        },
    },
    methods: {
        _goToRoute(path) {
            var selectedChannel = 1;
            if(path == '/home'){
                selectedChannel = this.$cookies.get('sbc_home_channel') ? this.$cookies.get('sbc_home_channel') : this.userInfo.home_channel[0];
                this.$cookies.set("sbc_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }else if(path == '/LOTTO'){
                selectedChannel = this.$cookies.get('sbc_slot_channel') ? this.$cookies.get('sbc_slot_channel') : 90
                this.$cookies.set("sbc_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }else if(path == '/live'){
                selectedChannel = this.$cookies.get('sbc_live_channel') ? this.$cookies.get('sbc_live_channel') : this.userInfo.live_channel[0]
                this.$cookies.set("sbc_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }
            this.$router.push({ path }).catch(() => { /* ignore */ });
        },
        _onSubmitDrawer(data) {
            this.$emit("invokeDrawer", data);
        },
        currencyFormat(num) {
            if (num) {
                num = parseFloat(num)
                return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
            return num
        },
        changeSound() {
            this.UPDATE_SOUND(!this.isSound)
        },
        playBgAudio() {
            this.UPDATE_AUDIO(!this.isAudioPlay)
            this.pauseAll();
            if (this.isAudioPlay) {
                this.play()
            }
        },
         async play() {
            var chosenNumber = Math.floor(Math.random() * this.audios.length);
            //grab the name:
            this.chosenName = this.audios[chosenNumber];
            this.chosenName.isPlaying = true;
            this.chosenName.file.play();
            this.chosenName.file.addEventListener("ended", async ()=>{
                this.pauseAll();
                await this.play();
            } );
            // chosenName.loop = true;
        },
        pauseAll() {
            this.audios.filter(audioItem => audioItem.isPlaying).forEach(filteredAudioItem => {
                filteredAudioItem.isPlaying = false;
                filteredAudioItem.loop = false;
                filteredAudioItem.file.pause();
            })
        },
        pause(chosenName) {
            chosenName.isPlaying = false;
            chosenName.file.pause();
        },
        ...mapMutations("$_modules", [
            "UPDATE_SOUND",
            "UPDATE_AUDIO",
            "UPDATE_CHANNEL_ID"
        ]),
    }
}
</script>
<style>
.dancing-image {
    animation: dancing-image 1s ease infinite;
  }
  
  @keyframes dancing-image {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.04, 1.04);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  .dancing-image:hover {
    box-shadow: 0px 2px 0px #f7bf30;
    -moz-box-shadow: 0px 2px 0px #f7bf30;
    -webkit-box-shadow: 0px 2px 0px #f7bf30;
    animation : none;
    cursor: pointer;
  }
  .dancing-image-active{
    box-shadow: 0px 2px 0px #f7bf30;
    -moz-box-shadow: 0px 2px 0px #f7bf30;
    -webkit-box-shadow: 0px 2px 0px #f7bf30;
    animation : none;
    cursor: pointer;
  }
  .lotto-home:hover{
    cursor: pointer;
  }
  .format-currency{
    font-size:1.4rem;
    margin-top : 1px;
    font-weight: 500;
    color:gold;
    margin-left:2px;
}
@media (min-width: 300px) and (max-width: 729px) {
    .display_balance {
        position: absolute;
        top: 35px;
        right: -10px;
    }
    .nav-credits-wr {
        margin-top: -10px!important;
        padding-top:0px!important;
        padding-right:6%;
    }
    .gp-top-nav .gp-credits .credits-data {
        background: rgb(0, 51, 102, 0.9);
        padding: 0.2em 1.2em;
        border-radius: 50px 50px 50px 50px;
        font-size: 15px;
        font-weight: 500;
        margin-right: 15px;
    }
    .chicken-logo{
        width:42px;
        height:42px;
        margin-left:5px;
    }
    .slot-logo{
        width:42px;
        height:42px; 
        margin-top:2px;
    }
}
@media (min-width: 730px) {
    .display_balance {
        position: relative;
    }
    .nav-credits-wr {
        margin-top: -5px!important;
        padding-top:8px!important;
        padding-right:0px;
    }
    .gp-top-nav .gp-credits .credits-data {
        background: rgb(0, 51, 102, 0.9);
        padding: 0.4em 1.2em;
        border-radius: 50px 50px 50px 50px;
        font-size: 20px;
        font-weight: 500;
        margin-right: 15px;
    }
    .chicken-logo{
        width:47px;
        height:47px;
    }
    .slot-logo{
        width:47px;
        height:47px; 
    }
}
</style>
